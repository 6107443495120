/* eslint-disable quotes */
import { accent0, focus, gray3, gray7, gray1, gray8, gray2, gray0, gray6 } from './colors';

export const theme = {
    name: "Navegate Emerald",
    rounding: 8,
    spacing: 24,
    defaultMode: "dark",
    palette: {
        primary: {
            main: accent0,
        },
    },
    global: {
        focus:{
            border: {
                color: focus
            }
        },
        hover:{
            background:{
                color: gray7
            }
        },
        colors: {
            brand: accent0,
            background: {
                dark: gray1,
                light: gray8
            },
            "background-strong": {
                dark: "#212226",
                light: "#fff"
            },
            "background-weak": {
                dark: gray2,
                light: gray8
            },
            "background-xweak": {
                dark: "#5e606e",
                light: gray7
            },
            text: {
                dark: gray8,
                light: gray0
            },
            "text-strong": {
                dark: "#FFFFFF",
                light: "#000"
            },
            "text-weak": {
                dark: gray7,
                light: gray2
            },
            "text-xweak": {
                dark: gray6,
                light: "#5e606e"
            },
            border: "background-xweak",
            control: "brand",
            "active-background": "background-weak",
            "active-text": "text-strong",
            "selected-background": "background-strong",
            "selected-text": "text-strong",
            "status-critical": "#e53642",
            "status-warning": "#fbb03b",
            "status-ok": accent0,
            "status-unknown": "#7f8194",
            "status-disabled": "#7f8194"
        },
        font: {
            family: "Gotham",
            size: "18px",
            height: "24px",
            maxWidth: "432px"
        },
        graph: {
            colors: {
                dark: [
                    "brand"
                ],
                light: [
                    "brand"
                ]
            }
        },
        control: {
            border: {
                radius: "0"
            }
        },
        borderSize: {
            xsmall: "1px",
            small: "2px",
            medium: "4px",
            large: "12px",
            xlarge: "24px"
        },
        breakpoints: {
            small: {
                value: 768,
                borderSize: {
                    xsmall: "1px",
                    small: "2px",
                    medium: "4px",
                    large: "6px",
                    xlarge: "12px"
                },
                edgeSize: {
                    none: "0px",
                    hair: "1px",
                    xxsmall: "2px",
                    xsmall: "3px",
                    small: "6px",
                    medium: "12px",
                    large: "24px",
                    xlarge: "48px"
                },
                size: {
                    xxsmall: "24px",
                    xsmall: "48px",
                    small: "96px",
                    medium: "192px",
                    large: "384px",
                    xlarge: "768px",
                    full: "100%"
                }
            },
            medium: {
                value: 1536
            },
            large: {}
        },
        edgeSize: {
            none: "0px",
            hair: "1px",
            xxsmall: "3px",
            xsmall: "6px",
            small: "12px",
            medium: "24px",
            large: "48px",
            xlarge: "96px",
            responsiveBreakpoint: "small"
        },
        input: {
            padding: "12px",
            weight: 600
        },
        spacing: "24px",
        size: {
            xxsmall: "48px",
            xsmall: "96px",
            small: "192px",
            medium: "384px",
            large: "768px",
            xlarge: "1152px",
            xxlarge: "1536px",
            full: "100%"
        }
    },
    button: {
        border: {
            radius: "8px"
        }
    },
    checkBox: {
        check: {
            radius: "8px"
        },
        toggle: {
            radius: "8px"
        }
    },
    radioButton: {
        check: {
            radius: "8px"
        }
    },
    select: {
        background: "white",
        options:{
            text:{
                "font-weight": "normal"
            }
        },
        icons: {
            color: gray3
        },
    },
    calendar: {
        small: {
            fontSize: "14px",
            lineHeight: 1.375,
            daySize: "27.428571428571427px"
        },
        medium: {
            fontSize: "18px",
            lineHeight: 1.45,
            daySize: "54.857142857142854px"
        },
        large: {
            fontSize: "30px",
            lineHeight: 1.11,
            daySize: "109.71428571428571px"
        }
    },
    clock: {
        analog: {
            hour: {
                width: "8px",
                size: "24px"
            },
            minute: {
                width: "4px",
                size: "12px"
            },
            second: {
                width: "3px",
                size: "9px"
            },
            size: {
                small: "72px",
                medium: "96px",
                large: "144px",
                xlarge: "216px",
                huge: "288px"
            }
        },
        digital: {
            text: {
                xsmall: {
                    size: "10px",
                    height: 1.5
                },
                small: {
                    size: "14px",
                    height: 1.43
                },
                medium: {
                    size: "18px",
                    height: 1.375
                },
                large: {
                    size: "22px",
                    height: 1.167
                },
                xlarge: {
                    size: "26px",
                    height: 1.1875
                },
                xxlarge: {
                    size: "34px",
                    height: 1.125
                }
            }
        }
    },
    heading: {
        level: {
            1: {
                small: {
                    size: "34px",
                    height: "40px",
                    maxWidth: "816px"
                },
                medium: {
                    size: "50px",
                    height: "56px",
                    maxWidth: "1200px"
                },
                large: {
                    size: "82px",
                    height: "88px",
                    maxWidth: "1968px"
                },
                xlarge: {
                    size: "114px",
                    height: "120px",
                    maxWidth: "2736px"
                }
            },
            2: {
                small: {
                    size: "26px",
                    height: "32px",
                    maxWidth: "624px"
                },
                medium: {
                    size: "34px",
                    height: "40px",
                    maxWidth: "816px"
                },
                large: {
                    size: "50px",
                    height: "56px",
                    maxWidth: "1200px"
                },
                xlarge: {
                    size: "66px",
                    height: "72px",
                    maxWidth: "1584px"
                }
            },
            3: {
                small: {
                    size: "22px",
                    height: "28px",
                    maxWidth: "528px"
                },
                medium: {
                    size: "26px",
                    height: "32px",
                    maxWidth: "624px"
                },
                large: {
                    size: "34px",
                    height: "40px",
                    maxWidth: "816px"
                },
                xlarge: {
                    size: "42px",
                    height: "48px",
                    maxWidth: "1008px"
                }
            },
            4: {
                small: {
                    size: "18px",
                    height: "24px",
                    maxWidth: "432px"
                },
                medium: {
                    size: "18px",
                    height: "24px",
                    maxWidth: "432px"
                },
                large: {
                    size: "18px",
                    height: "24px",
                    maxWidth: "432px"
                },
                xlarge: {
                    size: "18px",
                    height: "24px",
                    maxWidth: "432px"
                }
            },
            5: {
                small: {
                    size: "16px",
                    height: "22px",
                    maxWidth: "384px"
                },
                medium: {
                    size: "16px",
                    height: "22px",
                    maxWidth: "384px"
                },
                large: {
                    size: "16px",
                    height: "22px",
                    maxWidth: "384px"
                },
                xlarge: {
                    size: "16px",
                    height: "22px",
                    maxWidth: "384px"
                }
            },
            6: {
                small: {
                    size: "14px",
                    height: "20px",
                    maxWidth: "336px"
                },
                medium: {
                    size: "14px",
                    height: "20px",
                    maxWidth: "336px"
                },
                large: {
                    size: "14px",
                    height: "20px",
                    maxWidth: "336px"
                },
                xlarge: {
                    size: "14px",
                    height: "20px",
                    maxWidth: "336px"
                }
            }
        }
    },
    paragraph: {
        small: {
            size: "14px",
            height: "20px",
            maxWidth: "336px"
        },
        medium: {
            size: "18px",
            height: "24px",
            maxWidth: "432px"
        },
        large: {
            size: "22px",
            height: "28px",
            maxWidth: "528px"
        },
        xlarge: {
            size: "26px",
            height: "32px",
            maxWidth: "624px"
        },
        xxlarge: {
            size: "34px",
            height: "40px",
            maxWidth: "816px"
        }
    },
    text: {
        xsmall: {
            size: "12px",
            height: "18px",
            maxWidth: "288px"
        },
        small: {
            size: "14px",
            height: "20px",
            maxWidth: "336px"
        },
        medium: {
            size: "18px",
            height: "24px",
            maxWidth: "432px"
        },
        large: {
            size: "22px",
            height: "28px",
            maxWidth: "528px"
        },
        xlarge: {
            size: "26px",
            height: "32px",
            maxWidth: "624px"
        },
        xxlarge: {
            size: "34px",
            height: "40px",
            maxWidth: "816px"
        }
    },
    formField: {
        border: false,
        label: {
            margin: {
                vertical: '0',
                bottom: '.25em',
                horizontal: '0'
            },
            size: 'inherit'
        }
    }
};

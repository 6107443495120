import 'emerald-ui/dist/utilities/fontAwesomeStyles.css'; // for some reason importing @fortawesome/fontawesome-svg-core/styles.css directly here does not work
import 'emerald-ui/dist/globals/fonts.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import { Grommet } from 'grommet';
import Head from 'next/head';
import React, { useEffect } from 'react';
import { forwarderConfig } from '../globals/ForwarderConfig';
import { GlobalStyle } from '../globals/globalScrollbarStyle';
import { theme } from '../globals/theme';

config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

/** @param {import('next/app').AppProps} props  */
export default function App(props) {
    const {
        Component,
        pageProps
    } = props;

    useEffect(() => {
        console.log(`quote-module version ${process.env.APP_VERSION}`);

        /**
         * webpack will not add this code block to a production build
         * https://webpack.js.org/guides/production/#specify-the-mode
         */
        if (process.env.NODE_ENV !== 'production') {
            import('emerald-ui/dist/utilities/consoleProxy').then(({ consoleProxy }) => {
                consoleProxy();
            });
        }
    }, []);

    return (
        <>
            <Head>
                <title>{process.env.FORWARDER} Quoting</title>
                <link
                    href={forwarderConfig[process.env.FORWARDER].faviconUrl}
                    rel='icon'
                />
            </Head>
            <Grommet
                full
                style={{ position: 'relative' }}
                theme={theme}
            >
                <GlobalStyle />
                <Component {...pageProps} />
            </Grommet>
        </>
    );
}

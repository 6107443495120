import { createGlobalStyle } from 'styled-components';
import alpha from '../utility/alpha';
import { gray5, gray7, gray8 } from './colors';

export const GlobalStyle = createGlobalStyle`
    @media screen and (min--moz-device-pixel-ratio:0) {
        scrollbar-width: thin;
        scrollbar-color: ${gray5}
    }

    &::-webkit-scrollbar {
        width: 1.25rem;
        height: 1.25rem;
    }
    &::-webkit-scrollbar-track {
        border-radius: 2rem;
        background: transparent;
        &:hover{
            background: ${alpha('#fff', .05)};
        }
    }
    &::-webkit-scrollbar-thumb {
        background: ${gray7} padding-box;
        border: 2px solid transparent;
        border-radius: 2rem;
        &:hover{
            background: ${gray8} padding-box;
        }
    }
    &::-webkit-scrollbar-corner {
        visibility: hidden;
    }
`;
